<template>
  <v-card>
    <v-navigation-drawer v-model="showFilters" right fixed temporary>
      <v-list>
        <v-list-item>
          <v-list-item-title>Mailbox List Filters</v-list-item-title>
          <v-list-item-action>
            <v-icon @click="showFilters = false">fal fa-times</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <span small style="margin-bottom:.5em">Box Number (Range)</span>
            <v-row>
              <v-col class="pb-0">
                <v-text-field v-model="filter.minBox" label="Min" type="number" class="hide-arrows" outlined @keyup="loadMailboxes()"></v-text-field>
              </v-col>
              <v-col class="pb-0">
                <v-text-field v-model="filter.maxBox" label="Max" type="number" class="hide-arrows" outlined @keyup="loadMailboxes()"></v-text-field>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-text-field v-model="filter.name" label="Name Filter" outlined @keyup="loadMailboxes()"></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.empty" :items="[{ text: 'All Boxes', value: null }, { text: 'Assigned Boxes', value: false }, { text: 'Empty Boxes', value: true }, { text: 'Multiple Students Assigned', value: 'multi' }, { text: 'Single Student Assigned', value: 'single' }]" label="Occupied/Empty" outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.personType" :items="['Student','Employee','Other']" label="Person Type(s)" multiple outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.classLevel" :items="['FR','SO','JR','SR']" label="Class Level(s)" multiple outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.hold" :items="[{ text: 'All Boxes', value: null }, { text: 'On Hold', value: true }, { text: 'Not on Hold', value: false }]" label="On Hold" outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.note" :items="[{ text: 'All Boxes', value: null }, { text: 'Has Note', value: true }, { text: 'No Note', value: false }]" label="Note" outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.active" :items="[{ text: 'All Boxes', value: null }, { text: 'Active Boxes', value: true }, { text: 'Inactive Boxes', value: false }]" label="Active" outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-select v-model="filter.newBox" :items="[{ text: 'All Boxes', value: null }, { text: 'Past 7 Days', value: 7 }, { text: 'Past 30 Days', value: 30 }, { text: 'Past 90 days', value: 90 }]" label="New Boxes" outlined @change="loadMailboxes()"></v-select>
        </v-list-item>
        <v-list-item>
          <v-btn v-if="hasFilters" color="error" text @click="clearFilters()">Reset Filters</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showFilters = false">Close</v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar>
      <v-toolbar-title>Mailbox Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="filter.name" label="Name Filter" solo hide-details @keyup="loadMailboxes()" style="max-width:300px">
        <template v-slot:prepend-inner>
          <v-icon small>fal fa-search</v-icon>
        </template>
        <template v-slot:append>
          <v-icon v-if="filter.name !== ''" @click="filter.name = ''; loadMailboxes()">fas fa-times-circle</v-icon>
        </template>
      </v-text-field>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-dialog v-model="exportDialog" width="500">
            <template v-slot:activator="{ on: dialogOn }">
              <v-btn v-on="{ ...tooltipOn, ...dialogOn }" icon>
                <v-icon>fal fa-file-excel</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Export List</v-card-title>
              <v-card-text>
                <p>This will export the currently-filtered list. Do you want to have a list by user or by mailbox?</p>
                <v-switch v-model="exportByUser" :label="exportByUser ? 'List of users' : 'List of mailboxes'"></v-switch>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" text @click="exportList()">Run Export</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="exportDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <span>Export the currently filtered list to Excel</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="showFilters = !showFilters">
            <v-icon>{{ hasFilters ? 'fas' : 'fal' }} fa-filter</v-icon>
          </v-btn>
        </template>
        <span>Show Filters</span>
      </v-tooltip>
      <v-menu v-model="actionMenu" offset-y close-on-content-click>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-running</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-dialog v-model="removeGradDialog" width="600" persistent>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>Remove Graduates</v-list-item-title>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title>Remove Graduates</v-card-title>
              <v-card-text v-if="gradsRemoved">The graduates have been marked for removal. A script is scheduled to run every morning at 7am which will update these in Banner and then remove them from their mailbox.</v-card-text>
              <v-alert v-else-if="removingGrads" type="info">Marked {{ removeGradCount }} out of {{ gradsToRemove }} grads to be removed. This process will complete in Banner the next weekday morning.</v-alert>
              <v-card-text v-else>This process will remove mailboxes for all graduated seniors, except for those who are on hold or have an active summer employment record. Will mark {{ gradsToRemove }} graduates to remove.</v-card-text>
              <v-alert v-if="gradsToRemove === 0" type="error">There are no graduates to remove. Either you are running this process too early or there are none of them</v-alert>
              <v-card-actions v-if="!removingGrads">
                <v-btn v-if="!gradsRemoved" color="success" text @click="removeGraduates">Remove Graduates</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="removeGradDialog = false">{{ gradsRemoved ? 'Close' : 'Cancel' }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-list-item>
            <v-list-item-title>Remove Non-Returners</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-data-table :items="boxes" :headers="header" :server-items-length="boxCount" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @update:options="loadMailboxes">
      <template v-slot:item.box="{ item }">Box {{ item.box }}</template>
      <template v-slot:item.active="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" :color="item.active ? 'success' : 'error'">fal fa-{{ item.active ? 'check' : 'times' }}</v-icon>
          </template>
          <span v-if="item.active">Mailbox is active</span>
          <span v-else>Mailbox is inactive</span>
        </v-tooltip>
      </template>
      <template v-slot:item.users="{ item }">
        <v-dialog v-for="{ pidm, bannerId, name, hold, classLevel, personType, expGradDate, gradDate, action, summer } in item.users" :key="'user-' + pidm" width="600">
          <template v-slot:activator="{ on }">
            <v-badge v-if="hold" icon="fas fa-lock" color="error" overlap>
              <v-chip v-on="on" small>{{ name }} ({{ classLevel || personType }}) ({{ bannerId }})</v-chip>
            </v-badge>
            <v-chip v-else v-on="on" small>{{ name }} ({{ classLevel || personType }}) ({{ bannerId }})</v-chip>
          </template>
          <v-card>
            <v-card-title>{{ name }} ({{ bannerId }}) Details</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item v-if="hold">
                  <v-list-item-content>
                    <v-list-item-title>On Hold</v-list-item-title>
                    <v-list-item-subtitle>Mailbox is being held for {{ name }}, so they will not be removed automatically</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="gradDate">
                  <v-list-item-content>
                    <v-list-item-title>Graduated</v-list-item-title>
                    <v-list-item-subtitle>{{ name }} graduated on {{ gradDate.substring(0, 10)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else-if="expGradDate">
                  <v-list-item-content>
                    <v-list-item-title>{{ classLevel === 'SR' ? 'Senior' : (classLevel === 'JR' ? 'Junior' : (classLevel === 'SO' ? 'Sophomore' : 'Freshman')) }}</v-list-item-title>
                    <v-list-item-subtitle>Expected to graduate on {{ expGradDate.substring(0, 10)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="summer">
                  <v-list-item-content>
                    <v-list-item-title>Summer Worker</v-list-item-title>
                    <v-list-item-subtitle>{{ name }} is working on campus for the summer</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="action === 'add'">
                  <v-list-item-content>
                    <v-list-item-title>Added</v-list-item-title>
                    <v-list-item-subtitle>{{ name }} was recently added to the mailbox and is pending being added in Banner</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else-if="action === 'remove'">
                  <v-list-item-content>
                    <v-list-item-title>Removed</v-list-item-title>
                    <v-list-item-subtitle>{{ name }} was recently marked for removal and is pending being removed in Banner</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <p>Click off this card to close it</p>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.hold="{ item }">
        <div v-for="{ pidm, hold } in item.users" :key="'userHold-' + pidm">{{ hold ? 'Y' : 'N' }}</div>
      </template>
      <template v-slot:item.personType="{ item }">
        <div v-for="{ pidm, personType } in item.users" :key="'userType-' + pidm">{{ personType }}</div>
      </template>
      <template v-slot:item.classLevel="{ item }">
        <div v-for="{ pidm, classLevel } in item.users" :key="'userLevel-' + pidm">{{ classLevel }}</div>
      </template>
      <template v-slot:item.edit="{ item }">
        <mailbox-dialog :id="item._id" :item="item" @reload="loadMailboxes()"></mailbox-dialog>
      </template>
    </v-data-table>
  </v-card>
</template>
<style>
.hide-arrows input::-webkit-outer-spin-button,
.hide-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<script>
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import { saveAs } from 'file-saver'

export default {
  components: {
    MailboxDialog: () => import('@/components/mailroom/admin/mailboxDialog')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user)
    const service = root.$feathers.service('mailroom/mailbox')
    const showFilters = ref(false)
    const filter = ref({
      minBox: null,
      maxBox: null,
      name: '',
      empty: null,
      personType: [],
      classLevel: [],
      hold: null,
      note: null,
      active: null,
      newBox: null
    })
    const hasFilters = computed(() => {
      if (filter.value.minBox != null && filter.value.minBox !== '') return true
      if (filter.value.maxBox != null && filter.value.maxBox !== '') return true
      if (filter.value.name !== '') return true
      if (filter.value.empty != null) return true
      if (filter.value.personType.length > 0) return true
      if (filter.value.classLevel.length > 0) return true
      if (filter.value.hold != null) return true
      if (filter.value.note != null) return true
      if (filter.value.active != null) return true
      if (filter.value.newBox != null) return true
      return false
    })
    const boxCount = ref(0)
    const boxes = ref([])

    const header = ref([
      { text: 'Box', value: 'box' },
      { text: 'Active', value: 'active' },
      { text: 'Assigned User(s)', value: 'users' },
      { text: 'Note', value: 'note' },
      { text: 'Edit', value: 'edit', sortable: false }
    ])
    const gridOptions = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['box'],
      sortDesc: [true]
    })
    async function loadMailboxes (options) {
      if (options) {
        for (let l in options) {
          gridOptions.value[l] = options[l]
        }
      }
      const query = buildQuery(true)
      const { total, data } = await service.find({ query })
      boxCount.value = total
      boxes.value = data
    }

    function buildQuery (paginate) {
      const query = {}
      const { page, itemsPerPage, sortBy, sortDesc } = gridOptions.value
      if (paginate) {
        query.$limit = itemsPerPage
        query.$skip = (page - 1) * itemsPerPage
      }
      if ((filter.value.minBox != null && filter.value.minBox !== '') || (filter.value.maxBox != null && filter.value.maxBox !== '')) {
        query.box = {}
        if (filter.value.minBox != null && filter.value.minBox !== '') query.box.$gte = filter.value.minBox
        if (filter.value.maxBox != null && filter.value.maxBox !== '') query.box.$lte = filter.value.maxBox
      }
      if (filter.value.name !== '') {
        let str = filter.value.name
        if (str.indexOf(',') >= 0) {
          const [last, first] = str.split(',')
          str = first.trim() + '.*' + last.trim()
        } else {
          str = str.replace(/[ ]/, '.*')
        }
        query.users = { $elemMatch: { name: { $regex: str, $options: 'i' } } }
      }
      if (filter.value.empty != null) {
        if (filter.value.empty === 'multi') {
          query.empty = false
          query['users.1'] = { $exists: true }
        } else if (filter.value.empty === 'single') {
          query.empty = false
          query['users.1'] = { $exists: false }
        } else {
          query.empty = filter.value.empty
        }
      }
      if (filter.value.personType.length > 0) {
        if (!('users' in query)) query.users = { $elemMatch: {} }
        query.users.$elemMatch.personType = { $in: filter.value.personType }
      }
      if (filter.value.classLevel.length > 0) {
        if (!('users' in query)) query.users = { $elemMatch: {} }
        query.users.$elemMatch.classLevel = { $in: filter.value.classLevel }
      }
      if (filter.value.hold != null) {
        if (!('users' in query)) query.users = { $elemMatch: {} }
        query.users.$elemMatch.hold = filter.value.hold
      }
      if (filter.value.note === true) {
        query.note = { $exists: filter.value.note, $nin: ['', null] }
      } else if (filter.value.note === false) {
        query.$or = [{ note: { $exists: false } }, { note: '' }, { note: null }]
      }
      if (filter.value.active != null) {
        query.active = filter.value.active
      }
      if (filter.value.newBox != null) {
        const addDate = new Date()
        addDate.setDate(addDate.getDate() - filter.value.newBox)
        if (!('users' in query)) query.users = { $elemMatch: {} }
        query.users.$elemMatch.addedDate = { $gte: addDate }
      }
      if (sortBy.length > 0) {
        query.$sort = {}
        for (let i = 0; i < sortBy.length; i++) {
          if (sortBy[i] === 'users') query.$sort['users.name'] = sortDesc[i] ? -1 : 1
          else query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      }
      return query
    }

    function clearFilters () {
      filter.value.minBox = ''
      filter.value.maxBox = ''
      filter.value.name = ''
      filter.value.empty = null
      filter.value.personType = []
      filter.value.classLevel = []
      filter.value.hold = null
      filter.value.note = null
      filter.value.active = null
      filter.value.newBox = null
      loadMailboxes()
    }

    onMounted(async () => {
      loadMailboxes()
    })

    const actionMenu = ref(false)
    const removeGradDialog = ref(false)
    const gradsToRemove = ref(null)
    const removingGrads = ref(false)
    const removeGradCount = ref(0)
    const gradsRemoved = ref(false)
    const gradQuery = {
      users: {
        $elemMatch: {
          personType: 'Student',
          gradDate: { $lt: new Date() },
          $or: [
            { hold: { $exists: false } },
            { hold: false }
          ],
          summer: false
        }
      }
    }
    watch(removeGradDialog, (val) => {
      if (!val) {
        actionMenu.value = false
      } else {
        service.find({ query: { ...gradQuery, $limit: 0 } }).then(({ total }) => {
          gradsToRemove.value = total
        })
      }
    })
    async function removeGraduates () {
      removingGrads.value = true
      const query = { ...gradQuery, $limit: 0 }
      const { total } = await service.find({ query })
      gradsToRemove.value = total
      query.$limit = 20
      const now = new Date()
      removeGradCount.value = 0
      for (let i = 0; i < total; i += 20) {
        query.$skip = i
        const { data } = await service.find({ query })
        for (const { _id, users } of data) {
          // Build the object to patch
          const patch = {}
          let usersToRemove = 0
          for (let j = 0; j < users.length; j++) {
            if (!('gradDate' in users[j]) || users[j].gradDate != null) {
              const gradDate = new Date(users[j].gradDate)
              if (gradDate < now) {
                // Do not remove any users who are on hold
                if ('hold' in users[j] && users[j].hold === true) continue
                patch['users.' + j + '.action'] = 'remove'
                usersToRemove++
              }
            }
          }
          if (usersToRemove > 0) {
            await service.patch(_id, patch)
            removeGradCount.value++
          }
        }
      }
      removingGrads.value = false
      gradsRemoved.value = true
    }

    const exportDialog = ref(false)
    const exportByUser = ref(false)
    async function exportList () {
      const query = buildQuery(false)
      query.$limit = 0
      const { total } = await service.find({ query })
      query.$limit = 50
      const rows = []
      const header = [{ header: 'Box', align: 'left', key: 'box', width: 10 }]
      if (exportByUser.value) {
        header.push({ header: 'Banner ID', align: 'left', key: 'bannerId', width: 11 })
        header.push({ header: 'Last Name', align: 'left', key: 'last', width: 25 })
        header.push({ header: 'First Name', align: 'left', key: 'first', width: 25 })
        header.push({ header: 'Person Type', align: 'left', key: 'personType', width: 15 })
        header.push({ header: 'Class Level', align: 'left', key: 'classLevel', width: 12 })
        header.push({ header: 'Graduation Date', align: 'left', key: 'gradDate', width: 17 })
        header.push({ header: 'On Hold', align: 'left', key: 'hold', width: 8 })
        header.push({ header: 'Summer Worker', align: 'left', key: 'summer', width: 15 })
        if (user.value.username === 'jon.moon') header.push({ header: 'Action', align: 'left', key: 'action', width: 10 })
      } else {
        header.push({ header: 'Active', align: 'left', key: 'active', width: 8 })
        header.push({ header: 'Banner ID', align: 'left', key: 'bannerId0', width: 11 })
        header.push({ header: 'Name', align: 'left', key: 'name0', width: 25 })
      }
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        const { data } = await service.find({ query })
        data.forEach((row) => {
          if (exportByUser.value) {
            row.users.forEach((user) => {
              // ToDo: check the filters and filter out users who do not match the filter (only include the users in the mailbox who do match the filter)
              if (filter.value.name !== '') {
                const regex = new RegExp(query.users.$elemMatch.name.$regex, 'ig')
                if (!regex.test(user.name)) return
              }
              if (filter.value.personType.length > 0) {
                let included = false
                for (let z = 0; z < filter.value.personType.length; z++) {
                  if (filter.value.personType[z] === user.personType) {
                    included = true
                    break
                  }
                }
                if (!included) return
              }
              if (filter.value.classLevel.length > 0) {
                let included = false
                for (let z = 0; z < filter.value.classLevel.length; z++) {
                  if (filter.value.classLevel[z] === user.classLevel) {
                    included = true
                    break
                  }
                }
                if (!included) return
              }
              if (filter.value.hold != null && filter.value.hold !== user.hold) return
              if (filter.value.newBox != null) {
                const filterDate = new Date()
                filterDate.setDate(filterDate.getDate() - filter.value.newBox)
                const dateAdded = new Date(user.dateAdded)
                if (dateAdded < filterDate) return
              }
              rows.push({ box: 'Box ' + row.box, ...user, gradDate: (user.gradDate ? user.gradDate.substring(0, 10) : '') })
            })
          } else {
            const temp = { box: 'Box ' + row.box, active: row.active }
            for (let i = 0; i < row.users.length; i++) {
              temp['name' + i] = row.users[i].name
              temp['bannerId' + i] = row.users[i].bannerId
              if (header.length < (i * 2) + 4) {
                header.push({ header: 'Banner ID ' + (i + 1), align: 'left', key: 'bannerId' + i, width: 11 })
                header.push({ header: 'Name ' + (i + 1), align: 'left', key: 'name' + i, width: 25 })
              }
            }
            rows.push(temp)
          }
        })
      }
      if (rows.length > 0) {
        const filename = 'Mailbox Export.xlsx'
        root.$feathers.service('export/xlsx').create({ rows, header, filename }).then((data) => {
          saveAs(new Blob([data.buffer]), filename)
        })
      }
    }

    return {
      user,
      showFilters,
      filter,
      hasFilters,
      boxCount,
      boxes,
      header,
      gridOptions,
      loadMailboxes,
      clearFilters,
      actionMenu,
      removeGradDialog,
      gradsToRemove,
      removingGrads,
      removeGradCount,
      gradsRemoved,
      removeGraduates,
      exportDialog,
      exportByUser,
      exportList
    }
  }
}
</script>
